.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@-webkit-keyframes swing
{
  15%
  {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  30%
  {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  50%
  {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  65%
  {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  80%
  {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  100%
  {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes swing
{
  15%
  {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  30%
  {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  50%
  {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
  }
  65%
  {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }
  80%
  {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }
  100%
  {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.swing
{
  -webkit-animation: swing 1s ease;
  animation: swing 1s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.selectable {
  cursor: pointer !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
